
@use "sass:map";

@import '../../styles/pallet.scss' ;

.cards-container{
    display: flex;
    flex-flow: row;

    
    [class^='card'] {
        margin-right: 20px;
        &:last-child{
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
    
    @include screen-max(map-get($grid-breakpoints,'md')){
        flex-flow: column;
        [class^='card'] {
            margin-right: 0px;
            &:last-child{
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }
}

@each $var in $sizes {
    
        @each $bp-name,$bp-value in $grid-breakpoints {
            @include screen-max($bp-value){
                // @debug card-#{$bp-name}-#{$var};
            .card-#{$bp-name}-#{$var} {
                max-width: $bp-value;
                width: calc(#{$var}% - 40px);
            }
            .card-#{$var}{
                &.#{$bp-name}-left{
                    align-self: flex-start;
                }
                &.#{$bp-name}-center{
                    align-self: center;
                }
                &.#{$bp-name}-right{
                    align-self: end;
                }
            }
        }

    }

    .card-#{$var} {
        padding: 20px ;
        background-color: $primary-color;
        &:hover{
            box-shadow: 0px 4px 6px rgba(0,0,0,0.25);
        }
        box-shadow: 0px 2px 3px rgba(0,0,0,0.25);
        width: calc(#{$var}% - 40px);
        margin-bottom: 20px;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 28px;
        color: $secondary-accent;
        display: flex;
        flex-flow: column;
        .flex{
            display: flex;
        }
        &.secondary{
            background-color: $secondary-color;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
            .title,*{
                color: $primary-accent;
            }
        }
        &.left{

        }
        &.center{
            align-self: center;
        }
        &.right{
            align-self: end;
        }
        .title{
            font-family: 'Roboto Mono', monospace;
            font-size: 32px;
            font-weight: 700;
            color: $primary-accent;
            margin-bottom: 20px;

        }
        *{
            margin: 0;
        }
        &:last-child{
            margin-bottom: 50px;
        }
      }    
}