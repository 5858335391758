$primary-color: #F8B195;
$secondary-color: #F67280;
$tertiary-color: #C06C84;
$primary-accent: #6C5B7B;
$secondary-accent: #355C7D;


$sizes:10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;


$top-spacing:18rem;
$primary-background:$primary-color;
$page-background-color:$secondary-color;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);


@mixin screen-max($max) { 
    @media (max-width: $max) { 
      @content 
    } 
  };