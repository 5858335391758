// @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,500;1,600;1,700&display=swap');
// 
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,500;1,600;1,700&family=The+Nautigal&display=swap');

html,body{
  margin: 0;
  border: 0;
  outline: 0ch;
  height: 100vh;
  width: 100vw;
  cursor:url('./assets/Arrow.png'), auto;
}

.decorative-text{
  font-family: 'The Nautigal', cursive;
}

.small{
  font-size: 16px;
}
.medium{
  font-size: 32px;
}
.large{
  font-size: 48px!important;
}